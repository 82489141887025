import PublicCommonFooter from './components/PublicCommonFooter';
import PublicCommonHeader from './components/PublicCommonHeader';

function Policy() {
  return (
    <>
      <PublicCommonHeader />
      <div className="cms-page">
        <div className="container">
          <div className="content-center">
            <h1>privacy policy</h1>
            <p>
              Counselvise is committed to protecting your right to privacy as a
              user of our online documents. It is our policy to respect the
              privacy of private communication. The information you provide to
              us will be held for Counselvise servers based in the Country of
              India, and will not transfer it, or authorise its transfer,
              outside the India. We collect information about our users in order
              to help us continually improve the products and facilities we
              offer and so that we can enter into commercial arrangements,
              including the sale of advertising space.
            </p>
            <p>
              We appreciate the confidence you invest in us and understand how
              crucial secure transactions and information privacy are. This
              Privacy Policy describes how Counselvise (collectively
              “Counselvise/we/our/us”) collect, use, share or otherwise process
              of users personal information (collectively “Users/you/your”)
              through Counselvise’s website (hereinafter referred to as the
              “Platform / Website”). This Privacy Policy only relates to the
              Counselvise’s Website and does not extend to your use of the
              Internet outside of the Counselvise Website.
            </p>
            <p>
              Your personal information will primarily be stored and processed
              in India and may have data protection laws that are different from
              those that apply in the country in which you are located.
            </p>
            <p>
              You expressly agree to be bound by the terms and conditions of
              this Privacy Policy, the Terms of Use, and the applicable
              service/product terms and conditions by visiting this
              Platform/Website, providing your information, or using the
              product/service. You also expressly agree to be governed by the
              laws of India, including but not limited to the laws governing
              data protection and privacy. Please refrain from using or gaining
              access to our platform if you disagree.
            </p>
            <h4>What Data Do We Collect?</h4>
            <p>
              We aim to keep personal data collection and storage to a minimum.
              We collect and use the following personal (and non-personal) data:
            </p>
            <ul>
              <li>Your registration and payment information;</li>
              <li>Customer name;</li>
              <li>Email address;</li>
              <li>Address;</li>
              <li>Telephone number;</li>
              <li>
                Business sector (chosen from a limited non-specific list);
              </li>
              <li>
                Your subscription history (folder or package, start and end or
                renewal date);
              </li>
              <li>Your document download history;</li>
              <li>Your IP address.</li>
            </ul>
            <h4>Collection of Your Personal Data</h4>
            <p>
              “Personal Information” shall mean the information which identifies
              the User, name, identification number, email address, age, gender,
              phone number or any such above mentioned data provided to us at
              the time of registration or any time thereafter. Personal
              information may include “sensitive personal information or data”
              as defined under the Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal Data or
              Information) Rules, 2011 (herein “the Rules”) and in this context,
              includes passwords or any financial account information provided
              by you during registration.
            </p>
            <p>
              We do not monitor your use of the internet, but we do use cookies
              and similar technologies, including web analytics, to monitor your
              use of the platform. This information is not stored alongside your
              personal data and will only be used on an anonymous, aggregated
              basis. When you use our Platform/Website, we collect and store
              your information which is provided by you from time to time. In
              general, you can browse the Platform without telling us who you
              are or revealing any personal information about yourself. Once you
              give us your personal information, you are not anonymous to us.
              Where possible, we indicate which fields are required and which
              fields are optional. You always have the option to not provide
              information by choosing not to use a particular service, product
              or feature on the Platform/Website.
            </p>
            <p>
              We may track your search behaviour, preferences, and other
              information that you choose to provide on our Platform/Website. We
              use this information to do internal research on our users'
              demographics, interests, and behaviour to better understand,
              protect and serve our users. This information is compiled and
              analysed on an aggregated basis. This information may include the
              URL that you just came from (whether this URL is on our Platform
              or not), which URL you next go to (whether this URL is on our
              Platform or not), your computer browser information, and your IP
              address.
            </p>
            <p>
              When you create an account, transact with us, or take part in an
              event or contest, we may ask you for your personal information
              (including your email address, delivery address, name, phone
              number, credit card or debit card information, and details of
              other payment instruments). Some areas of our platform allow you
              to explore without registering, but other activities (such placing
              a purchase or using our online content or services) do require
              registration. We send you offers based on Your past purchases and
              interests using the information provided by You on our Platform.
            </p>
            <p>
              We will collect the information You give us if You choose to post
              messages on our message boards, chat rooms, or other message
              areas, or if You choose to leave feedback on the Platform or the
              social media accounts we maintain, or if You choose to use a
              feature similar to shopping on the Platform/Website. As permitted
              by law, we keep this information as long as it's required to
              settle disputes, offer customer support, troubleshoot issues, or
              conduct internal research and analysis.
            </p>
            <p>
              If You send us personal correspondence, such as emails or letters,
              or if other users or third parties send us correspondence about
              Your activities or postings on the Platform, we may collect such
              information into a zip file specific to You.
            </p>
            <p>
              If You enrol into any type of Program or similar programs offered
              by Counselvise Platform, we will collect and store Your personal
              information such as name, contact number, email address,
              communication address, date of birth, gender, zip code, lifestyle
              information, demographic and work details which is provided by You
              to Counselvise or a third-party business partner or service
              provider that operates online/offline establishments or platforms.
              Counselvise shall not be responsible for the third-party business
              partner’s privacy practices or the content of their privacy
              policies, and we request You to read their privacy policies prior
              to disclosing any information.
            </p>
            <p>
              Ensuring compliance with third-party platform policies. We
              strictly follow Google's User Data Policy, including limited use
              requirements for data received from Google APIs when creating a
              Calendar Event.
            </p>
            <h4>What Do We Use Your Data For?</h4>
            <p>We use your personal data for the following purposes:</p>
            <ul>
              <li>
                Providing and managing your account and/or subscription(s).
              </li>
              <li>
                Supplying you with information, documents, forms, and other
                content either through the Website or by email. You can control
                your email preferences from ‘My Account’ and can opt out of our
                newsletters and bulletins when you register (or at any time
                thereafter). Please note that some emails are an integral part
                of the service you sign up for when registering with Counselvise
                and cannot be opted out of and that by registering, you are
                consenting to us using your personal data to send such emails.
                We will never send you any spam and always aim to ensure that
                our emails will be genuinely useful to recipients.
              </li>
              <li>
                Communicating with you. This may include responding to emails or
                calls from you.
              </li>
              <li>
                Building up a profile of your interests and preferences based on
                your download history.
              </li>
            </ul>
            <p>
              Our processing of your personal data is in our legitimate
              interests and is necessary to provide the service to you and to
              continually improve it and the content available through it.
            </p>
            <p>
              Our use of your personal data is limited to that which is
              reasonably required in order to provide our service to you and to
              make improvements to that service which benefit both us and you.
              We do not use your personal data for any reasons not stated in
              this Privacy Policy and never use it in an excessive manner that
              is disproportionate to our aims of providing and improving our
              service or in a way that is contrary to your interests.
            </p>
            <p>
              In addition, when you purchase a subscription from us, your
              personal data is necessary for the formation (and subsequent
              performance) of the contract between you and Counselvise and the
              completion of your purchase.
            </p>
            <p>
              With Your consent, we may have access to Your SMS, instant
              messages, contacts in Your directory, location, camera, photo
              gallery and device information.
            </p>
            <p>
              We identify and use Your IP address to help diagnose problems with
              our server, and to administer our Platform. Your IP address is
              also used to help identify You and to gather broad demographic
              information.
            </p>
            <p>
              We will occasionally ask You to participate in optional surveys
              conducted either by us or through a third-party market research
              agency. These surveys may ask You for personal information,
              contact information, date of birth, demographic information ,
              attributes such as Your interests, household or lifestyle
              information, Your purchasing behaviour or history, preferences,
              and other such information that You may choose to provide. The
              surveys may involve collection of voice data or video recordings,
              the participation of which would purely be voluntary in nature.
            </p>
            <p>
              We use this data to tailor Your experience at our Platform,
              providing You with content that we think You might be interested
              in and to display content according to Your preferences.
            </p>
            <h4>How Long Do We Keep Your Personal Data?</h4>
            <p>
              The personal data that you provide when registering is retained
              until you ask us to delete it. If you wish to delete your account,
              you can do so using the ‘Delete My Account’ link in ‘My Account’.
              Download histories are retained indefinitely, however when an
              account is deleted, this information cannot be used to identify
              you. Deleted accounts cannot be reactivated.
            </p>
            <p>
              We also retain records of subscriptions and transactions for
              accounting purposes. Personal data contained within emails sent to
              us will be retained for as long as we reasonably require it.
              Questions relating to our content along with our answers to those
              questions may be retained unless you ask us not to in order to
              facilitate answering similar queries in the future, whether from
              you or from others. We also retain certain communications to aid
              in the conduct of potential future legal claims.
            </p>
            <h4>Do We Share Your Personal Data?</h4>
            <p>
              We may share personal information internally within Counselvise
              and its affiliates and with other third parties if needed for
              purposes of providing products and services offered by them, such
              as-
            </p>
            <p>
              i) We may disclose Your personal information to third parties,
              such as business partners and service providers. This disclosure
              may be required for us to provide You access to our products and
              services; for fulfilment of Your orders and services required; for
              enhancing Your experience; for providing feedback on products and
              services; to collect payments from You; to comply with our legal
              obligations; to conduct market research or surveys; to enforce our
              Terms of Use; to facilitate our marketing and advertising
              activities; to analyse data; for customer service assistance; to
              prevent, detect, mitigate, and investigate fraudulent or illegal
              activities related to our product and services. We do not disclose
              Your personal information to third parties for their marketing and
              advertising purposes without Your explicit consent.
            </p>
            <p>
              ii) We may disclose personal information if required to do so by
              law or in the good faith belief that such disclosure is reasonably
              necessary to respond to subpoenas, court orders, or other legal
              process. We may disclose personal information to law enforcement
              agencies, third party rights owners, or others in the good faith
              belief that such disclosure is reasonably necessary to: enforce
              our Terms of Use or Privacy Policy; respond to claims that an
              advertisement, posting or other content violates the rights of a
              third party; or protect the rights, property or personal safety of
              our users or the general public.
            </p>
            <p>
              We and our affiliates will share / sell some or all of Your
              personal information with another business entity should we (or
              our assets) plan to merge with, or be acquired by that business
              entity, or reorganization, amalgamation, restructuring of
              business. Should such a transaction occur then the other business
              entity (or the new combined entity) will also be required to
              follow this Privacy Policy with respect to Your personal
              information.
            </p>
            <h4>What Are Your Rights?</h4>
            <ul>
              <li>
                The right to be informed about our collection and use of your
                personal data (as described in this Privacy Policy).
              </li>
              <li>
                The right to access your personal data by means of a subject
                access request (see below).
              </li>
              <li>
                The right to have your personal data rectified if any of your
                personal data held by us is inaccurate or incomplete. You can do
                this either by contacting us or by clicking on the ‘Change My
                Details’ link in ‘My Account’.
              </li>
              <li>
                The right to erasure (also known as the right to be forgotten).
                You can exercise this right either by contacting us or by
                clicking on the ‘Delete My Account’ link in ‘My Account’. Please
                note that if you delete your account, it will be deleted
                immediately and if you have an active subscription, that
                subscription will be cancelled. Refunds are not available. If
                you wish to ensure that we delete data such as accounting data
                or emails from you, please contact us.
              </li>
              <li>
                The right to restrict or object to our processing of your
                personal data for particular purposes. Email preferences can be
                changed using the ‘Email Preferences’ link in ‘My Account’.
                Using this link you can opt-out of our newsletters and bulletins
                and, for expired subscriptions only, our alerts. For active
                subscriptions, alerts are an integral part of our service. For
                further information, please contact us.
              </li>
            </ul>
            <h4>How To Contact Us?</h4>
            <p>
              To contact Counselvise about anything to do with your personal
              data and data protection, including to make a subject access
              request, please use the following details and we will respond as
              soon as possible:
            </p>
            <ul>
              <li>Email:contact@counselvise.com</li>
              <li>Telephone:+91 97234 00220</li>
              <li>
                Postal Address:
                <br /> Flr-1, Shop -1, Plot-13, Silver Pam,
                <br /> Timaliyawad, Nanpura, Surat,
                <br /> Surat City, Gujarat, India, 395001
              </li>
            </ul>
            <h4>Cookies</h4>
            <p>
              We use data collection devices such as "cookies" on certain pages
              of the Platform to help analyse our web page flow, measure
              promotional effectiveness, and promote trust and safety. You may
              refer to our Cookie Policy for details on the same.
            </p>
            <p>
              We maintain reasonable physical, electronic and procedural
              safeguards to protect Your information. Whenever You access Your
              account information, we offer the use of a secure server. Once
              Your information is in our possession, we adhere to our security
              guidelines to protect it against unauthorized access. However, by
              using the Platform, the users accept the inherent security
              implications of data transmission over the internet which cannot
              always be guaranteed as completely secure, and therefore, there
              would always remain certain inherent risks regarding use of the
              Platform. Users are responsible for ensuring the protection of
              login and password records for their account.
            </p>
            <h4>Choice/Opt-Out</h4>
            <p>
              We provide all users with the opportunity to opt-out of receiving
              non-essential (promotional, marketing-related) communications
              after setting up an account with us via our Platform. If You do
              not wish to receive promotional communications from us, then
              unsubscribe/opt-out through email.
            </p>
            <h4>Children Information</h4>
            <p>
              Use of our Platform is available only to persons who can form a
              legally binding contract under the Indian Contract Act, 1872. We
              do not knowingly solicit or collect personal information from
              children under the age of 18 years. If You have shared any
              personal information of children under the age of 18 years, You
              represent that You have the authority to do so and permit us to
              use the information in accordance with this Privacy Policy.
            </p>
            <h4>Data Retention</h4>
            <p>
              We retain Your personal information in accordance with applicable
              laws, for a period no longer than is required for the purpose for
              which it was collected or as required under any applicable law.
              However, we may retain data related to You if we believe it may be
              necessary to prevent fraud or future abuse, to enable Counselvise
              to exercise its legal rights and/or defend against legal claims or
              if required by law or for other legitimate purposes. We may
              continue to retain Your data in anonymised form for analytical and
              research purposes.
            </p>
            <h4>Your Consent</h4>
            <p>
              By visiting our Platform or by providing Your information, You
              consent to the collection, use, storage, disclosure and otherwise
              processing of Your information (including sensitive personal
              information) on the Platform in accordance with this Privacy
              Policy.
            </p>
            <p>
              If You disclose to us any personal information relating to other
              people, You represent that You have the authority to do so and to
              permit us to use the information in accordance with this Privacy
              Policy.
            </p>
            <p>
              You, while providing Your personal information over the Platform
              or any partner platforms or establishments, consent to us
              (including our other corporate entities, affiliates, lending
              partners, 7 technology partners, marketing channels, business
              partners, service providers and other third parties) to contact
              You via Short Message Service (SMS), instant messaging apps, call
              and/or e- mail for the purposes specified in this Privacy Policy.
            </p>
            <h4>Governing Law and Jurisdiction</h4>
            <p>
              The Courts in India alone shall have exclusive jurisdiction for
              all and any matters arising out of or concerning or relating to or
              touching this Policy.
            </p>
            <p>
              In the event that any dispute arises between the Parties in
              connection with this Policy, the Parties shall conduct
              negotiations in good faith to solve such dispute. If mutual
              resolution cannot be reached within 15 (fifteen) days after the
              commencement of such negotiations, the Parties shall refer such
              dispute to a sole arbitrator. The arbitration proceedings shall be
              governed in accordance with the Arbitration and Conciliation Act,
              1996 and rules framed thereunder. The venue, place and seat of the
              arbitration shall be Surat, Gujarat and the language of the
              arbitration proceedings shall be English or Hindi or Gujarati. The
              award rendered shall be in writing, and shall set out the reasons
              for the sole arbitrator’s decision. The award shall allocate or
              apportion the costs of the arbitration as the sole arbitrator
              deems fair. Any award made in such arbitration will be final and
              binding on the Parties. This Policy, and the rights and
              obligations of the Parties shall remain in full force and effect
              pending the award in such arbitration proceeding, which award, if
              appropriate, shall determine whether and when, any termination
              shall become effective.
            </p>
            <h4>Changes To This Privacy Policy</h4>
            <p>
              We may change our Privacy Policy from time to time. This may be
              necessary, for example, if the law changes, or if we change our
              business in a way that affects personal data protection. Any
              changes will be notified to you the first time you login to
              Counselvise after the changes have been made.
            </p>
          </div>
        </div>
        <PublicCommonFooter />
      </div>
    </>
  );
}

export default Policy;
