import PublicCommonFooter from './components/PublicCommonFooter';
import PublicCommonHeader from './components/PublicCommonHeader';

function TermsAndCondition() {
  return (
    <>
      <PublicCommonHeader />
      <div className="cms-page">
        <div className="container">
          <div className="content-center">
            <h1>Terms and conditions</h1>
            <p>
              Thanks for using Counselvise. These General Terms of Service
              ("General Terms"), along with any applicable Additional Terms
              (defined below) (collectively, the “Terms”) cover your use and
              access to the products, services, software, platform and websites
              (collectively, "Services") provided by Counselvise incorporated
              and any of our affiliates (collectively, "Counselvise"). By using
              our Services, you agree to be bound by these General Terms as well
              as our Privacy Policy, Terms of Use and other policies available
              on the platform. If you are using our Services as the employee or
              agent of an organization, you are agreeing to these Terms on
              behalf of that organization. Please note that the statements set
              forth under the headings "More simply put" are provided as a
              courtesy solely for your convenience and are not legally binding
              or otherwise intended to modify these Terms in any way.
            </p>
            <p>
              You must be the legal age of majority in your state of residence
              or otherwise able to form a binding contract with Counselvise in
              order to use the Services. In no event is use of the Services
              permitted by those under the age of 18.
            </p>
            <h4>General terms</h4>
            <h5>1. Interactions with advisors</h5>
            <p>
              When you use our Services, you will have the opportunity to submit
              questions and obtain advice from outside independent advisors in
              the “Counselvise On Call” network, also referred to as On Call
              network advisors (“External Advisors”). You may also have an
              initial consultation or other interaction with an advisor prior to
              formally entering into an advisor- client relationship.
            </p>
            <p>
              <strong>Counselvise Attorneys</strong>
            </p>
            <p>
              If you engage a Counselvise Attorney to provide legal advice, you
              will need to enter into a separate engagement that creates an
              attorney-client relationship between you and our affiliate
              (“Counselvise Legal Engagement”). Additional terms and conditions
              will apply in that situation and will be provided to you prior to
              entering into the Counselvise Legal Engagement. There is no
              attorney-client relationship formed, and Counselvise is under no
              duty to act as your lawyer, unless and until a Counselvise Legal
              Engagement is signed by both you and Counselvise. If there is any
              conflict between the Counselvise Legal Engagement and these Terms,
              the Counselvise Legal Engagement shall govern.
            </p>
            <h5>2. External advisors (on call network advisors)</h5>
            <p>
              External Advisors are neither employees nor agents of Counselvise.
              External Advisors are third-party independent contractors who
              carry their own practice and have agreed to provide online
              answers, limited consultations or other legal services to
              Counselvise members at discounted rates.
            </p>
            <p>
              When you contact an External Advisor through Counselvise, any
              initial interactions and any relationship formed during the course
              of that interaction is strictly between you and the External
              Advisor and expressly EXCLUDES Counselvise.
            </p>
            <p>
              When you contact an External Advisor through Counselvise, he or
              she may ask you for some information regarding you and your legal
              affairs in order to properly address your questions. By using our
              Services, you consent to sharing such personally identifying
              information for the purpose of securing legal advice with both the
              requesting attorney and with Counselvise. Counselvise will not
              have access to information you provide directly to the External
              Advisors, except in cases where you do so through our platform, in
              which case we can access that information for fulfilment and
              quality assurance purposes.
            </p>
            <p>
              When you contact an External Advisor through Counselvise, you and
              the External Advisor control both the duration and scope of the
              interaction. Any relationship formed during the course of that
              interaction may either end when the interaction with the External
              Advisor ends, or continue if you and the External Advisor agree to
              continue the engagement for further services.
            </p>
            <p>
              Counselvise does not endorse or recommend any External Advisors
              and the call schedules with premium consultants are in nature of
              discovery call and do not guarantee any specific resolution of
              queries. You should carefully evaluate the experience and
              qualifications of any External Advisor before hiring them as your
              advisor. Counselvise makes no representation or warranty as to the
              qualifications or competency of any External Advisor or as to the
              accuracy or completeness of any External Advisor’s work.
            </p>
            <p>
              <strong>
                COUNSELVISE SHALL HAVE NO RESPONSIBILITY OR LIABILITY OF ANY
                KIND FOR ANY PROFESSIONAL SERVICES RENDERED BY ANY EXTERNAL
                ADVISOR YOU ENCOUNTER ON OR THROUGH OUR SERVICES, AND ANY USE OR
                RELIANCE ON SUCH PROFESSIONAL SERVICES IS SOLELY AT YOUR OWN
                RISK.
              </strong>
            </p>
            <h5>3. Termination of membership and access restriction</h5>
            <p>
              Counselvise reserves the right, in its sole discretion, to
              downgrade or terminate your access to the Services, for any reason
              and at any time without prior notice. For example, we may suspend
              or terminate your use of some or all of the Services if you
              violate these Terms or use the Services in a manner that may
              expose us to legal liability, disrupt the Services or disrupt
              others' use of the Services.
            </p>
            <p>
              If your Counselvise membership is paid for by a Program Sponsor
              and they elect to discontinue the service, you will have the
              opportunity to continue your membership personally by enrolling in
              a paid subscription.
            </p>
            <h5>4. Ownership and preservation of your documents</h5>
            <p>
              Counselvise does not claim ownership of any documents you either
              create or upload and store using our Services ("Documents"). You
              grant permission for Counselvise to use your Documents in
              connection with providing Services to you.
            </p>
            <p>
              You acknowledge and agree that Counselvise may preserve these
              Documents as well as disclose them if required to do so by law or
              in the good faith belief that such preservation or disclosure is
              reasonably necessary to accomplish any of the following: (1) to
              comply with legal process, applicable laws or government requests;
              (2) to enforce these Terms; (3) to respond to claims that any
              content violates the rights of third parties; or (4) to protect
              the rights, property, or personal safety of Counselvise, its users
              and the public. You understand that the technical processing and
              transmission of the Service, including your Documents and other
              content, may involve transmissions over third party networks and
              changes to conform and adapt to technical requirements of
              connecting networks or devices. You agree that Counselvise has no
              responsibility or liability for deleting or failing to store any
              Documents or other content maintained or uploaded in the Services.
            </p>
            <h5>5. Consent to receive emails</h5>
            <p>
              By creating an account, you agree that you may receive
              communications from Counselvise, such as newsletters, special
              offers, and account reminders and updates. You also understand
              that you can remove yourself from promotional communications by
              clicking the "Unsubscribe" link in the footer of the actual email.
              You cannot opt out of essential communications regarding your
              account, such as renewal notices.
            </p>
            <h5>6. Acceptable use of communications services</h5>
            <p>
              Our Services include a large number of what are collectively
              called "Communications Services." These include services such as
              live chats, payment Services, comment threads, blog posts,
              question and answer products, chat bots, customer service
              communication forums, calendars, and other message services. You
              agree to use the Communications Services only to post, send, and
              receive messages or materials proper to and related to the
              particular Communication Service. When using a Communication
              Service, you agree that you will not do any of the following:
            </p>
            <ul>
              <li>
                Defame, abuse, harass, stalk, threaten or otherwise violate the
                legal rights of others.
              </li>
              <li>
                Publish, post, upload, distribute or disseminate any names,
                materials, or information that is considered inappropriate,
                profane, defamatory, infringing, obscene, indecent, or unlawful.
              </li>
              <li>
                Create a false identity, represent yourself as someone else, or
                sign an agreement as someone else or on behalf of someone else
                or otherwise falsify or delete in an uploaded file any
                significant attributions or notices.
              </li>
              <li>
                Upload files that contain software or other material protected
                either by intellectual property laws or by the rights of privacy
                or publicity except when you own or control the necessary
                rights, or you have received all necessary consents to do so.
              </li>
              <li>
                Upload corrupted files, files that contain viruses, or any other
                files that may damage the operation of another's computer.
              </li>
              <li>
                Advertise, offer to sell, or offer to buy anything for business
                purposes except to the limited extent any particular
                Communication Service expressly allows such activity.
              </li>
              <li>
                Restrict or inhibit any other user from using and enjoying the
                Communications Services.
              </li>
              <li>
                Harvest or otherwise collect any information about other users,
                either individually or in the aggregate, or about the Services
                generally.
              </li>
              <li>
                Violate any code of conduct or other guidelines, which may be
                applicable for any particular Communication Service.
              </li>
              <li>Violate any applicable laws or regulations.</li>
            </ul>
            <p>
              Although Counselvise has no obligation to monitor the
              Communications Services, we reserve the right, in our own
              discretion, to review and remove materials posted to a
              Communication Service (in whole or in part), terminate or limit
              any user’s access to the Communications Services, or take other
              steps that we believe are appropriate to prevent abusive or
              unreasonably burdensome use of the Communications Services.
              Counselvise reserves the right to disclose any materials posted,
              information or activity as necessary to satisfy any applicable
              law, regulation, legal process, or governmental request.
            </p>
            <h5>7. NO unlawful or prohibited use</h5>
            <p>
              You can only use our Services if doing so complies with the laws
              of your jurisdiction(s). The availability of our Services in your
              jurisdiction(s) is not an invitation or authorization by
              Counselvise to access or use our website or Services in a manner
              that violates your local laws and regulations. By using our
              Services, you accept sole responsibility to ensure that you or
              anyone else who accesses your account to use our Services does not
              violate any applicable laws in your jurisdiction(s). To enforce
              this provision, Counselvise reserves the right to refuse
              membership, or suspend or terminate your account immediately and
              without prior notice at our sole discretion. The following are
              specifically excluded or prohibited:
            </p>
            <ul>
              <li>
                Use in connection with any legal matter that is frivolous,
                immaterial or illegal in nature, as determined by Counselvise or
                the External Advisors in their sole discretion;
              </li>
              <li>
                Use in connection with any legal matter involving an alleged
                violent crime by you;
              </li>
              <li>
                Use in connection with any legal matter involving the laws of
                jurisdictions outside India or its subdivisions;
              </li>
              <li>
                Use in connection with any legal matter for which you are
                currently or prospectively represented by legal counsel (other
                than an External Attorney or Counselvise Attorney).
              </li>
              <li>
                Use in connection with any legal matter that directly or
                indirectly involves any External Attorney or Counselvise
                Attorney other than as your counsel;
              </li>
              <li>
                Use in connection with any legal matter in which Counselvise or
                any of its subsidiaries, affiliates, directors, officers,
                agents, employees, or other Counselvise service providers has an
                adverse interest; or
              </li>
              <li>
                Use in connection with any legal matter in which your Program
                Sponsor has an adverse interest, or in which any director,
                officer or controlling stockholder of the Program Sponsor has an
                adverse interest.
              </li>
            </ul>
            <p>
              You may not "hack", "scrape" or "crawl" our Services, whether
              directly or through intermediaries such as spiders, robots,
              crawlers, scrapers, framing, iframes without express written
              permission from Counselvise. You may not access or attempt to
              access any information Counselvise has not intentionally made
              available to you on its Services via purchased subscription. Your
              use of the Counselvise Services does not entitle you to resell any
              Counselvise content without prior express written consent from
              Counselvise.
            </p>
            <h5>8. Intellectual property rights; license grant</h5>
            <p>
              Counselvise retains all right, title and interest in and to its
              products and Services, including, without limitation, software,
              images, text, graphics, illustrations, logos, service marks,
              copyrights, photographs, videos, music, articles, document
              templates and all related intellectual property rights. Except as
              otherwise provided in the Terms, you may not, and may not permit
              others to:
            </p>
            <ul>
              <li>
                reproduce, modify, translate, enhance, decompile, disassemble,
                reverse engineer or create derivative works of any of our
                products and Services (other than documents you create through
                your membership, which you may use for your own personal use);
              </li>
              <li>
                sell, license, sublicense, rent, lease, distribute, copy,
                publicly display, publish, adapt or edit any of our products and
                Services; or
              </li>
              <li>
                circumvent or disable any security or technological features of
                our products and Services.
              </li>
            </ul>
            <p>
              The design, text, graphics and selection and arrangement of our
              Services and the legal forms, documents, guidance and all other
              content found on our Service ("Service Content") are copyright ©
              Counselvise Incorporated. All rights reserved.
            </p>
            <p>
              You may not copy, imitate or use any of our trademarks, copyright
              or any other IP’s, in whole or in part, without our prior written
              consent. In addition, the look and feel of Counselvise is the
              service mark, trademark and/or trade dress of Counselvise
              Incorporated and you may not copy, imitate or use it, in whole or
              in part, without our prior written consent. The names of actual
              companies and products you might encounter through us may be the
              trademarks of their respective owners. Nothing in these Terms or
              the Services should be understood as granting any license or right
              to use any of Counselvise's or any third party’s trademarks
              displayed on our Services. All goodwill generated from the use of
              Counselvise's trademarks is reserved for the use of Counselvise,
              exclusively.
            </p>
            <p>
              Subject to your compliance with these Terms, you are hereby
              granted a non-exclusive, limited, non-transferable, revocable
              license to use the Services as we intend for them to be used. As a
              registered Counselvise user, you are the owner of and are fully
              authorized to keep, for your own personal records, electronic or
              physical copies of documents you have created on Counselvise.
            </p>
            <p>
              When you transmit public-facing user content on Counselvise, you
              hereby grant Counselvise and its affiliates a nonexclusive,
              royalty-free, perpetual, irrevocable and fully sublicensable right
              to use, reproduce, modify, adapt, publish, translate, create
              derivative works from, distribute, perform and display any such
              content, including throughout the world in any media. If you
              submit feedback or suggestions about our Services, we may use your
              feedback or suggestions without obligation or compensation to you.
              You acknowledge and agree that Counselvise is authorized to
              collect and use aggregated or anonymized information from or about
              you and other users and user-created documents for the purposes of
              researching, developing, improving and marketing its Services.
            </p>
            <p>
              Resale or unauthorized copying, use, storage, display or
              distribution of forms, articles, documents or other materials
              copied or downloaded from our Service is strictly prohibited. Use
              of these materials is for your personal or business use. Any
              resale or redistribution of our materials requires the express
              written consent of Counselvise. Any rights not expressly granted
              in these Terms are reserved by Counselvise.
            </p>
            <h5>9. Links to third party sites</h5>
            <p>
              Counselvise's Service may contain links to third party resources
              and businesses on the Internet, called here "links" or "Linked
              Site(s)." Those links are provided for your convenience to help
              you identify and locate other Internet resources that may be of
              interest to you.
            </p>
            <p>
              Counselvise does not control, endorse or monitor the contents of
              any Linked Site. That includes, without limitation, any further
              link contained in a Linked Site, and any changes or updates to a
              Linked Site. Counselvise is not responsible for webcasting or for
              any other form of transmission received from any Linked Site.
              These Terms do not cover your interaction with Linked Sites. You
              should carefully review the terms and conditions and privacy
              policies of any Linked Sites.
            </p>
            <p>
              If you use any service provided on a Linked Site, (a) Counselvise
              will not be responsible for any act or omission of the third
              party, including the third party's access to or use of your
              customer data and (b) Counselvise does not warrant or support any
              service provided by the third party.
            </p>
            <h5>10. Disclaimer Of Representations And Liability</h5>
            <p>
              <strong>
                Please read this section carefully as it affects your rights
              </strong>
            </p>
            <p>
              The information, software, products, and Services made available
              through Counselvise may include inaccuracies or typographical
              errors. Counselvise reserves the right at any time to modify,
              improve, suspend or deprecate certain features of our Services.
              Legal Information received via Counselvise should not be relied
              upon exclusively for personal, medical, legal or financial
              decisions; rather, we recommend you use information from our
              Service in conjunction with the advice of a qualified professional
              tailored to your situation. In short, your use of our Services is
              at your own risk.
            </p>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, COUNSELVISE AND ITS
              AFFILIATES, SUBSIDIARIES, PROGRAM SPONSORS AND DISTRIBUTORS MAKE
              NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE
              SERVICES ARE PROVIDED "AS IS” AND “AS AVAILABLE." WE ALSO DISCLAIM
              ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. INFORMATION AND OPINIONS RECEIVED
              VIA OUR SERVICES SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL,
              LEGAL OR FINANCIAL DECISIONS WITHOUT CONSULTING AN APPROPRIATE
              PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
            </p>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
              COUNSELVISE, ITS AFFILIATES, SUPPLIERS, PROGRAM SPONSORS OR
              DISTRIBUTORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
              PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE,
              DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR
              NOT COUNSELVISE HAS BEEN WARNED OF THE POSSIBILITY OF SUCH
              DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </p>
            <p>
              THE PARTIES EXPRESSLY AGREE AND ACKNOWLEDGE THAT THE FOREGOING
              DISCLAIMERS AND LIMITATIONS OF LIABILITY FORM AN ESSENTIAL BASIS
              OF THE BARGAIN BETWEEN THE PARTIES.
            </p>
            <h5>11. Release And Indemnity</h5>
            <p>
              By using our Service, you, on behalf of yourself and your heirs,
              executors, agents, representatives, and assigns, fully release,
              forever discharge, and hold Counselvise, its partners (including
              any third-party companies engaged by Counselvise to fulfill
              business formation, registered agent or other business filing
              orders), your Program Sponsor (if applicable) and its affiliates,
              and its and their respective officers, employees, directors and
              agents, harmless from any and all losses, damages, expenses,
              including reasonable attorneys' fees, rights, claims, and actions
              of any kind and injury (including death) arising out of or
              relating to your use of the Services. You agree that this release
              has been freely and voluntarily consented to and you confirm that
              you fully understand what you are agreeing to.
            </p>
            <p>
              You agree to indemnify and hold Counselvise, its partners
              (including any third-party companies engaged by Counselvise to
              fulfill business formation, registered agent or other business
              filing orders), your Program Sponsor (if applicable) and its
              affiliates, and its and their respective officers, employees,
              directors and agent harmless from any and all losses, damages,
              expenses, including reasonable attorneys' fees, rights, claims,
              actions of any kind and injury (including death) arising out of
              any third party claims relating to your use of the Service, your
              violation of these Terms or your violation of any rights of
              another.
            </p>
            <p>
              Program Sponsors are expressly intended third-party beneficiaries
              of the provisions of these Terms that expressly apply to Program
              Sponsors including, without limitation, this Section (Release and
              Indemnity) and Section of (No Unlawful or Prohibited Use).
            </p>
            <h5>12. Dispute Resolution By Binding Arbitration</h5>
            <p>
              In the event of any dispute, controversy, or claim arising out of
              or relating to this terms, the Parties shall conduct negotiations
              in good faith to solve such dispute. If mutual resolution cannot
              be reached within 30 (Thirty) days after the commencement of such
              discussions, the Parties agree to resolve the matter through
              mediation in accordance with The Mediation Act, 2023. The Parties
              shall mutually select a qualified mediator, or if they cannot
              agree on a mediator within 30 (Thirty) days, then a recognized
              mediation service provider shall appoint a mediator.
            </p>
            <p>
              The mediation process shall be confidential, binding, and without
              prejudice to the Parties’ legal rights. The costs of the mediation
              shall be shared equally between the Parties unless otherwise
              agreed. The competent courts at Surat shall have the jurisdiction
              to undertake mediation at any place within the territorial
              jurisdiction or via online mediation through Zoom/ Google meet or
              preferred platform.
            </p>
            <p>
              A mediated settlement agreement resulting from a mediation signed
              by the Parties and authenticated by the mediator shall be final
              and binding on the Parties and persons claiming under them
              respectively and enforceable as per the provisions of Section 27
              (2) of The Mediation Act, 2023.
            </p>
            <p>
              Where no agreement is reached between the parties within the 30
              (Thirty) days or the mediator is of the opinion that settlement is
              not possible, he shall prepare a non-settlement agreement.
            </p>
            <p>
              If mutual resolution cannot be reached through Mediation, the
              Parties shall refer such dispute to arbitration as per the
              Arbitration and Conciliation Act, 1996 in accordance with the
              arbitration rules for the time being in force. The Parties can
              mutually agree and appoint a sole arbitrator or 3 (three)
              arbitrators. The seat of the arbitration shall be Surat and the
              language of the arbitration proceedings shall be English. The
              Parties can also opt to resolve the dispute(s) in order to reach
              amicable resolution through online mode via Zoom/ Google Meet
              platform. The award rendered shall be in writing, and shall set
              out the reasons for the arbitrator’s decision.
            </p>
            <p>
              Any award made in such arbitration will be final and binding on
              the Parties.
            </p>
            <h5>13. Governing Law And Jurisdiction</h5>
            <p>
              These terms shall be construed and interpreted in accordance with
              and governed by the laws of India, and the courts of Surat,
              Gujarat shall have the exclusive jurisdiction over matters arising
              out of or relating to these terms.
            </p>
            <h5>14. Entire Agreement</h5>
            <p>
              These Terms constitute the entire agreement between you (Advise
              Seeker) and Counselvise with respect to the subject matter of
              these Terms, and supersede and replace any other prior or
              contemporaneous agreements, or terms and conditions applicable to
              the subject matter of these Terms.
            </p>
            <h4>Additional Terms</h4>
            <h5>1. Non-employment Relationship:</h5>
            <p>
              <strong>Independent Arrangement:</strong> The Advisor acknowledges
              and agrees that providing Services through Counselvise does not
              establish an employment relationship with Counselvise but
              constitutes an independent arrangement for offering specialized
              advice or Services.
            </p>
            <h5>2. Premium & Non-premium Advisors:</h5>
            <p>
              <strong>Categorization:</strong> Advisors may be designated as
              Standard Professional Consultants or Premium Professional
              Consultants based on criteria set by Counselvise, with Premium
              Professional Consultants possibly receiving specific benefits or
              features distinct from Standard Professional Consultants. These
              terms shall be applicable to both former and latter.
            </p>
            <h5>3. Expertise & Best Advice:</h5>
            <p>
              <strong> Best Knowledge:</strong> The Advisor commits to provide
              advice or services to the best of their knowledge, expertise, and
              professional capacity within their specialized area.
            </p>
            <p>
              <strong>Fee Structure:</strong> Counselvise has established under
              its agreements / disclaimer a set payment terms, including fee
              structures, payment schedules and settlement of the invoices for
              the Services rendered by the Advisor. Standard Professional
              Consultants and Premium Professional Consultants may have
              different payment arrangements.
            </p>
            <p>
              <strong>Payment Collection:</strong>
            </p>
            <p>
              <strong>1. Standard Professional Consultants:</strong>
              In case of standard professional consultants, the advisor agrees
              that the Counselvise does not provide any discovery of their
              microsite. It only provides tool to create microsite through a
              separate domain – Meraoffice.in for them to share within their
              network, to manage appointment and video calls.
            </p>
            <p>
              Therefore, the payment is not handled by Counselvise. It shall be
              managed internally between standard professional consultants and
              Advise Seekers. Counselvise makes all efforts that the client of
              the standard professional consultants are not marketed the
              services of Counselvise.
            </p>
            <p>
              <strong>2. Premium Professional Consultants:</strong> Counselvise
              may handle payment collection from Advise Seekers for Services
              rendered by the Premium Professional Consultants. They will
              receive compensation according to the agreed-upon terms.
            </p>
            <p>
              <strong>3. CONFIDENTIALITY:</strong>
            </p>
            <p>
              <strong>Confidential Information:</strong> The Advisor agrees to
              maintain the confidentiality of all information, discussions, and
              materials shared during the provision of services. This includes
              but is not limited to user details, consultations, and any
              proprietary information disclosed.
            </p>
            <p>
              <strong>Non-Disclosure:</strong> The Advisor shall not disclose,
              share, or use any user’s information obtained through
              Counselvise's platform for any purpose other than providing the
              services. This obligation survives the termination of this
              agreement.
            </p>
            <p>
              <strong>4. LEGAL COMPLIANCE & INDEMNIFICATION:</strong>
            </p>
            <p>
              <strong>Compliance: </strong> The Advisor shall adhere to all
              applicable laws and regulations while providing services.
              Counselvise shall be indemnified against any legal actions arising
              from the Advisor's and its associate’s non-compliance with laws
              and its actions.
            </p>
            <p>
              <strong>5. LIABILITY & DISCLAIMERS:</strong>
            </p>
            <p>
              <strong>No Liability:</strong> Counselvise shall not be liable for
              the actions, advice, or services provided by the Advisor. The
              Advisor assumes sole responsibility and liability for the advice
              or Services rendered.
            </p>
            <p>
              <strong>
                6. TERMINATION, MODIFICATION & DISPUTE RESOLUTION:
              </strong>
            </p>
            <p>
              <strong>Termination & Modification: </strong> Counselvise reserves
              the right to terminate an Advisor's access or modify these Terms
              at any time, with written notice to Advisors.
            </p>
            <p>
              <strong>Dispute Resolution:</strong>
              Disputes between Advisors and Counselvise shall be resolved as per
              the dispute resolution mechanisms outlined in the agreement /
              disclaimer.
            </p>
            <p>
              <strong>Governing Law:</strong> This terms shall be construed and
              interpreted in accordance with and governed by the laws of India,
              and the courts of Surat, Gujarat shall have the exclusive
              jurisdiction over matters arising out of or relating to these
              terms.
            </p>
            <p>
              <strong>Disclaimer:</strong>
            </p>
            <p>
              By providing advice or services through Counselvise, Advisors
              acknowledge and agree to abide by these Terms, understanding their
              obligations, responsibilities, and limitations while using
              Counselvise's platform to offer Services to users.
            </p>
          </div>
        </div>
        <PublicCommonFooter />
      </div>
    </>
  );
}

export default TermsAndCondition;
