import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { counselviseClient } from '../../../apollo';
import { FETCH_USER_QUERY } from '../../../app/components/Queries';
import { NoticeBoard } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import { Cookie } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';

type UserType = {
  id?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  profileImage?: string | null;
};

function PublicCommonHeader() {
  const { navigate } = useRouter();
  const idToken = Cookie.get(Cookie.keys.TOKEN);
  const [userData, setUserData] = useState<UserType | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const redirectProfile = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}/profile`;

  const [getUserDate] = useLazyQuery(FETCH_USER_QUERY, {
    client: counselviseClient,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (idToken) {
      getUserDate({
        onCompleted: (res) => {
          setIsAuthenticated(true);
          const userData = res?.currentUser as UserType;
          setUserData(userData);
          setName(
            `${userData?.firstName?.[0] ?? ''}${userData?.lastName?.[0] ?? ''}`,
          );
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <header>
      <div className={'container height-full d-flex'}>
        <div className="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <span
                onClick={() => navigate(`${ROUTES?.MAIN}`)}
                className="d-flex align-center"
              >
                <NoticeBoard />
              </span>
            </div>
          </div>
          <div className="header-right">
            {isAuthenticated ? (
              <ul>
                <li className="m-hide">
                  <div
                    className="profile-btn pointer"
                    onClick={() => window.open(redirectProfile, '_self')}
                  >
                    <Tooltip title="My Profile">
                      {userData?.profileImage ? (
                        <Avatar alt={name} src={userData?.profileImage} />
                      ) : (
                        <Avatar alt={name}>{name}</Avatar>
                      )}
                    </Tooltip>
                  </div>
                </li>
              </ul>
            ) : (
              <div className="btn-wrapper">
                <Button
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}${ROUTES?.LOGIN}/?redirect=${window?.location?.href}`)
                  }
                  type="default"
                >
                  Log in
                </Button>
                <Button
                  type="primary"
                  className="m-hide"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}${ROUTES?.SIGNUP}/?redirect=${window?.location?.href}`)
                  }
                >
                  Sign Up
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default PublicCommonHeader;
